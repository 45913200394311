import {GetStaticPaths, GetStaticProps} from "next";
import React from "react";
import CommonHOCComponent from "../../_hoc/Common";
import GetAppVersion from "../../_hoc/GetAppVersion";
import GetStaticPageData from "../../_hoc/GetStaticPageData";
import {getData} from "../../lib/network/api";
import {ICommonData,} from "../../lib/types/common";
import {IHomeApiData, IHomeBannerTestingData, IHomePageLanguageData} from "../../lib/types/home";
import {IHomePageUSLanguageData, IHomeUSApiData,} from "../../lib/types/home.us";
import {ABTestingData, BaseProps} from "../../lib/types/Misc.type";
import {
    GET_APP_URL,
    getGetAppPagesWithLocales,
    VariantB,
} from "../../lib/utils/constants/GlobalConstants";
import {COUNTRY, LANGUAGE_COUNTRY,} from "../../lib/utils/constants/LocaleAndCountryConstants";
import {
    combineHOC,
    getCountryFromLocale,
    getNewHomePageABTestingData,
    getStaticPathWithLocale,
} from "../../lib/utils/constants/PagesConstants";
import IndexPageIndiaV2 from "../../Layouts/India/IndexPageIndiaV2";
import {
    GAMESMANIA_DOT_IN,
    getWebsiteEnvironment,
    WINFANTASY_DOT_IN
} from "../../lib/utils/constants/EnvironmentConstants";

interface Props extends BaseProps {
    apiData: IHomeApiData | IHomeUSApiData | any;
    autoScrollToElement: () => void;
    url: string;
    installStepContainer: React.RefObject<HTMLElement>;
    downloadUrl: string;
    androidUrl: string;
    abTestingData: ABTestingData
}

function HomeNew(props: Props) {
    return <IndexPageIndiaV2 {...props} />;
}

export const getStaticPaths: GetStaticPaths = async () => {
    return {
        paths: getStaticPathWithLocale(getGetAppPagesWithLocales),
        fallback: false,
    };
};

export const getStaticProps: GetStaticProps = async ({params, locale}) => {
    const pageName: string = params!!.slug as string;
    let jsonName: string = pageName;


    // let abTestingData = await getABTestingData();
    const getAdditionalData = async (data: IHomePageLanguageData | IHomePageUSLanguageData, commonData: ICommonData,
                                     abTestingData: ABTestingData) => {
        let variant = VariantB
        let newHomePageABTestingData: IHomeBannerTestingData | null = getNewHomePageABTestingData(abTestingData);
        if (newHomePageABTestingData
            && (newHomePageABTestingData.executeTesting.includes("get-app")
                || newHomePageABTestingData.executeTesting.includes("get-app-ref")
                || newHomePageABTestingData.executeTesting.includes("get-app-af"))) {
            variant = newHomePageABTestingData.variant;
        }

        if (locale === LANGUAGE_COUNTRY.EN_IN && getWebsiteEnvironment().baseUrl === GAMESMANIA_DOT_IN && pageName === GET_APP_URL) {
            data = await getData(locale, pageName + "-gamesmania-dot-in")
        } else if (locale === LANGUAGE_COUNTRY.EN_IN && getWebsiteEnvironment().baseUrl === WINFANTASY_DOT_IN && pageName === GET_APP_URL) {
            data = await getData(locale, pageName + "-winfantasy-dot-in")
        }
        return {variant, data, commonData: data, abTestingData}
    }

    return await GetStaticPageData<IHomePageLanguageData | IHomePageUSLanguageData>(getData, jsonName, pageName, pageName,
        getCountryFromLocale(locale) === COUNTRY.INDIA ? LANGUAGE_COUNTRY.EN_IN : locale, undefined,
        15, getAdditionalData);
};
export default combineHOC(CommonHOCComponent, GetAppVersion)(HomeNew);
